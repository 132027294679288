function interactivePanel(el) {
  const tabs = el.querySelectorAll("a.tab");
  function collectionUpdate(item) {
    const tag = document.querySelector(`[data-id="${item.id}"]`);
    if (item.checked) {
      tag.classList.add("selected");
      window.stagedProducts.add(
        JSON.stringify({
          sku: item.dataset.productSku,
          quantity: 1,
          id: item.id
        })
      );
    } else {
      window.stagedProducts.delete(
        JSON.stringify({
          sku: item.dataset.productSku,
          quantity: 1,
          id: item.id
        })
      );
      tag.classList.remove("selected");
    }
    if (el.querySelectorAll(".selected").length) {
      document.body.classList.add("select-mode-active");
    } else {
      document.body.classList.remove("select-mode-active");
    }

    const addToCartButton = document.getElementById("add-to-cart");
    const addToCollectionButton = document.getElementById("add-to-collection");
    const collectionBarButton = document.querySelector(
      ".collection-bar-button"
    );

    addToCollectionButton.classList.remove("noswatches");
    addToCollectionButton.classList.remove("success");
    addToCollectionButton.innerHTML =
      "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> Add to collection";

    addToCartButton.classList.remove("noswatches");
    addToCartButton.classList.remove("success");

    addToCartButton.innerHTML =
      "<i class='fas fa-shopping-cart'></i> Add to sample cart";
    collectionBarButton.innerHTML = "Cancel";
    collectionBarButton.classList.remove("done");

    if (!document.body.className.match("select-mode-active")) {
      addToCollectionButton.classList.add("noswatches");
      addToCollectionButton.innerHTML =
        "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> No swatches selected <span class='feature-tooltip'>To add samples to the collection, you must first select them by clicking on the swatches below.</span>";
      addToCartButton.classList.add("noswatches");
      addToCartButton.innerHTML =
        "<i class='fas fa-shopping-cart'></i> No swatches selected <span class='feature-tooltip'>To add samples to the sample cart, you must first select them by clicking on the swatches below.</span>";
    }

    collectionBar.updateCount();
  }

  /**
   * change tab styling on checkbox toggle
   */
  const checkboxes = el.querySelectorAll("[type=checkbox]");
  checkboxes.forEach(checkbox => {
    checkbox.addEventListener("change", function() {
      collectionUpdate(this);
    });
  });

  /**
   * change pin styling on tab hover
   */
  tabs.forEach(tab => {
    tab.addEventListener("mouseover", function() {
      const pinId = this.previousElementSibling.id;
      const pin = document.querySelector(`[data-id="${pinId}"]`);
      pin.classList.add("active");
    });
    tab.addEventListener("mouseout", function() {
      const pinId = this.previousElementSibling.id;
      const pin = document.querySelector(`[data-id="${pinId}"]`);
      pin.classList.remove("active");
    });
  });

  /**
   * change tab styling on pin hover
   */
  const pins = el.querySelectorAll(".letter-tag");
  pins.forEach(pin => {
    let input = document.getElementById(pin.dataset.id);

    if (input) {
      let tab = input.nextElementSibling;

      pin.addEventListener("mouseover", function() {
        tab.classList.add("active");
        pin.classList.add("active");
      });

      pin.addEventListener("mouseout", function() {
        tab.classList.remove("active");
        pin.classList.remove("active");
      });

      pin.addEventListener("click", function() {
        input.checked = !input.checked;
        pin.classList.toggle("selected");
        collectionUpdate(input);
      });
    }
  });
}

export default interactivePanel;
