// form functionality and validation
export default class Form {

    constructor(element) {
        this.form = element
    }

    formMessage(messages) {
        messages.forEach((message) => {
            message.querySelector('.close').addEventListener('click', function(){
                this.parentNode.style.display = 'none'
            })
        })
    }
    
    hasError(field){
        if (field.disabled || field.type === 'submit' || field.type === 'reset' || field.type === 'button' || field.type === 'hidden') return
        
        // if valid return null
        if(field.validity.valid) return

        // check if empty
        if(field.value === '') return 'This field is mandatory'
        
        // check if input is valid
        if(field.validity.typeMismatch){
            // check email is correct
            if(field.type === 'email'){
                return 'Please enter a valid email address'
            } else {
                return 'Enter the correct format'
            }
        }

        // catchall generic error message
        return 'The value you entered for this field is invalid'
    }
    
    showError(field, error){
        field.classList.add('error')
        const id = field.id || field.name

        if(!id) return

        let message = field.form.querySelector(`.error-message#error-for-${id}`)
        if(!message){
            message = document.createElement('div')
            message.className = 'error-message'
            message.id = `error-for-${id}`

            let label
            if(!label) field.parentNode.appendChild(message)
        }

        field.setAttribute('aria-describedby', `error-for-${id}`)

        message.innerHTML = error
        message.style.display = 'block'
        message.style.visibility = 'visible'
    }

    removeError(field){
        field.classList.remove('error')
        field.removeAttribute('aria-describedby')

        const id = field.id || field.name
        if(!id) return

        const message = field.form.querySelector(`.error-message#error-for-${id}`)
        if (!message) return

        message.innerHTML = ''
        message.style.display = 'none'
        message.style.visibility = 'hidden'
    }

    load() {
        // form message
        const messages = document.querySelectorAll('.form-message')
        if(messages) this.formMessage(messages)

        // get form JS ready
        this.form.setAttribute('novalidate', true)
        this.form.addEventListener('submit', (e) => {
            const fields = this.form.elements

            let error, hasErrors
            for (let index = 0; index < fields.length; index++) {
                error = this.hasError(fields[index])
                if(error){
                    this.showError(fields[index], error)
                    if(!hasErrors) hasErrors = fields[index]
                }
            }

            // if errors, dont submit form, focus on first error element
            if(hasErrors){
                e.preventDefault()
                this.form.classList.add('submitted')
                hasErrors.focus()
            } else {
                // let the form submit
                this.form.submit()
            }
        })

        // recheck fields after submission
        const fields = this.form.querySelectorAll('[required]')
        fields.forEach((field) => { // after submitted, if errors remove them once user fixes and blurs off
            field.addEventListener('blur', (e) => {
                if (this.form.classList.contains('submitted')) {
                    const error = this.hasError(e.target)

                    if (error) {
                        this.showError(e.target, error)
                        return
                    }
                    this.removeError(e.target)
                }
            })
        })
    }
}
