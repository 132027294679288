export default class CollectionSlider {

    constructor(element) {
        this.position = 0;
        this.grid = element;
        this.sliderWidth = element.parentElement.offsetWidth;
        this.parent = element.parentElement.parentElement;
        this.dots = this.parent.querySelectorAll('.dot');
        this.dots.forEach((dot, index) => {
            dot.addEventListener('click', () => {
                this.goto(index);
            });
        });
        if(this.parent.querySelector('.js-collection-next')) {
            this.parent.querySelector('.js-collection-next').addEventListener('click', this.next.bind(this));
        }
        if(this.parent.querySelector('.js-collection-prev')) {
            this.parent.querySelector('.js-collection-prev').addEventListener('click', this.prev.bind(this));
        }
    }

    goto(position) {
        this.position = position;
        this.grid.style.marginLeft = (-this.sliderWidth - 40) * this.position + 'px';
        this.dots.forEach((dot, index) => {
            dot.classList.remove('active');
            if (index == position) {
                dot.classList.add('active');
            }
        });
    }

    next() {
        if(this.position < (this.dots.length - 1)) {
            this.position++;
            this.goto(this.position);
        }
    }
    
    prev() {
        if(this.position) {
            this.position--;
            this.goto(this.position);
        }
    }

}
