import autocomplete from "./search.js";
import carouselInit from "./carousel.js";
import interactivePanel from "./interactivePanel.js";

const addToCollectionModal = (el, type, data) => {
    el.classList.add("loading");
    window.collection.add(type, el.dataset[data]);
    window.collection.save().then(res => {
        collection.state.id = res;
        document.cookie =
            "collection=" +
            res +
            "; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/";
        el.classList.remove("loading");
        el.classList.add("success");
    });
};

function closeNotification() {
    const shareNotifications = document.querySelectorAll(".share-notification");

    if (shareNotifications) {
        document.body.classList.remove("has-notification");
        shareNotifications.forEach(shareNotification => {
            shareNotification.classList.add("is-hidden");
        });
    }
}

function shareBtn() {
    const copyLinks = document.querySelectorAll("[data-copy-link]");
    const shareCloseAll = document.querySelectorAll(".share-close");
    const sa = [...document.querySelectorAll(".share-action")];

    sa.map(s => {
        s.addEventListener("click", e => {
            e.preventDefault();

            closeNotification();

            const shareTooltip = s.parentNode.querySelector(".share-tooltip");
            const shareAction = s;

            var el = s;
            let elClone = el.cloneNode(true);
            el.parentNode.replaceChild(elClone, el);

            if (shareTooltip.classList.contains("is-hidden")) {
                shareTooltip.classList.remove("is-hidden");
                shareAction.classList.add("open");
            } else {
                shareTooltip.classList.add("is-hidden");
                shareAction.classList.remove("open");
            }
        });
    });

    if (shareCloseAll) {
        shareCloseAll.forEach(shareClose => {
            shareClose.addEventListener("click", e => {
                e.preventDefault();
                const shareTooltip = shareClose.parentNode.parentNode.querySelector(
                    ".share-tooltip"
                );
                if (shareTooltip) {
                    shareTooltip.classList.add("is-hidden");
                }
            });
        });
    }

    if (copyLinks) {
        copyLinks.forEach(copyLink => {
            copyLink.addEventListener("click", e => {
                e.preventDefault();
                copyTextToClipboard(copyLink.dataset.copyLink);
                copyLink.parentNode.classList.add("is-hidden");
            });
        });
    }

    if (copyLinks) {
        copyLinks.forEach(copyLink => {
            copyLink.addEventListener("click", e => {
                e.preventDefault();
                copyTextToClipboard(copyLink.dataset.copyLink);
                copyLink.parentNode.parentNode.classList.add("is-hidden");
            });
        });
    }

    const dismissNotifications = document.querySelectorAll(
        ".share-notification-dismiss"
    );

    if (dismissNotifications) {
        dismissNotifications.forEach(dismissNotification => {
            dismissNotification.addEventListener("click", e => {
                e.preventDefault();
                closeNotification();
            });
        });
    }
}

function showNotification() {
    const shareNotifications = document.querySelectorAll(".share-notification");
    if (shareNotifications) {
        shareNotifications.forEach(shareNotification => {
            shareNotification.classList.remove("is-hidden");
            document.body.classList.add("has-notification");
            window.scrollTo(0, 0);
        });
    }
}

function fallbackCopyTextToClipboard(text) {
    let textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        const successful = document.execCommand("copy");
        const msg = successful ? "successful" : "unsuccessful";
        //console.log('Fallback: Copying text command was ' + msg);
        if (successful) {
            showNotification();
        }
    } catch (err) {
        //console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        fallbackCopyTextToClipboard(text);
        return;
    }
    navigator.clipboard.writeText(text).then(
        function() {
            // console.log('Async: Copying to clipboard was successful!');
            showNotification();
        },
        function(err) {
            //console.error('Async: Could not copy text: ', err);
        }
    );
}

export const showModal = (type, item, options = "") => {
    let attribute, url, itemId;

    if (type === "image" && window.innerWidth >= 700 && options === "header") {
        return;
    }

    if (type === "image") {
        document.body.classList.add("image-modal");
    }

    closeNotification();

    switch (type) {
        case "product":
            attribute = "data-product-id";
            url = "/umbraco/surface/product/modal/";
            break;
        case "tree":
            attribute = "data-tree-id";
            url = "/umbraco/surface/tree/modal/";
            break;
        case "resource":
            attribute = "data-resource-id";
            url = "/umbraco/surface/resource/modal/";
            break;
        case "showcase":
            attribute = "data-showcase-id";
            url = "/umbraco/surface/inspiration/modal/?id=";
            break;
        case "image":
            attribute = "data-image-id";
            url = "/umbraco/surface/image/modal/?id=";
            break;
        case "specifier":
            attribute = "data-guide-id";
            url = "/umbraco/surface/guidelines/modal?id=";
            break;
        case "search":
            attribute = "data-search-id";
            url = "/umbraco/surface/searchmodal/modal?brandId=0&search=";
            break;
    }

    if (typeof item !== "string" && typeof item !== "number") {
        itemId = item.getAttribute(attribute);
    } else {
        itemId = item;
    }

    const modalUrl = url + itemId;
    document.body.classList.add("loading");
    fetch(modalUrl, { credentials: "same-origin" }).then(response => {
        if (response.status === 404 || response.status === 500) {
            document.body.classList.add("error");
            document.location.href = "/";
            return false;
        }
        response.text().then(html => {
            let e = document.createElement("div");
            e.innerHTML = html;
            while (e.firstChild) {
                document.body.appendChild(e.firstChild);
                document.body.classList.remove("nav-open");
                document.body.classList.remove("loading");
                document.body.classList.add("show-modal");
            }

            // load collection listeners
            loadCollectionListeners();

            // search functions
            if (type === "search") {
                if (options === "show-advanced") {
                    document
                        .querySelectorAll("#show-hide-search-filters")
                        .forEach(button => {
                            button.classList.add("active");
                            button.parentNode.classList.add("reset-active");
                        });
                    document
                        .querySelectorAll("#advanced-search-filters")
                        .forEach(section => section.classList.remove("remove"));
                }

                const colourData = document.body.querySelector(
                    "[data-colour-species]"
                ).dataset.colourSpecies;
                autocomplete("search-autocomplete", JSON.parse(colourData));
            }

            // alter full image modal
            if (type === "image") {
                window.location.hash = `#image-${itemId}`;
            } else {
                window.location.hash = `#${itemId}`;
            }

            document.body.addEventListener("click", function handleClick(e) {
                e.stopPropagation();

                if (e.target) {
                    // add product to cart event binding
                    if (e.target.id === "add-product-to-cart") {
                        const product = {
                            sku: e.target.dataset.productSku,
                            quantity: 1
                        };
                        fetch("/umbraco/surface/cart/savecartitem", {
                            method: "POST",
                            body: JSON.stringify(product),
                            credentials: "same-origin",
                            headers: {
                                "Content-Type": "application/json"
                            }
                        })
                            .then(res => {
                                return res.json();
                            })
                            .then(res => {
                                setTimeout(() => {
                                    e.target.classList.remove("loading");
                                    e.target.classList.add("success");
                                }, 200);

                                document.querySelector(
                                    ".js-cart-count"
                                ).innerHTML = res.TotalCartCount;
                            });
                    }

                    // add product to collection event binding
                    if (e.target.id === "add-product-to-collection") {
                        addToCollectionModal(e.target, "products", "productId");
                    }

                    // add resource to collection event binding
                    if (e.target.id === "add-resource-to-collection") {
                        addToCollectionModal(
                            e.target,
                            "resources",
                            "resourceId"
                        );
                    }

                    // add showcase to collection event binding
                    if (e.target.id === "add-showcase-to-collection") {
                        addToCollectionModal(
                            e.target,
                            "showcase",
                            "showcaseId"
                        );
                    }

                    document.body.removeEventListener("click", handleClick);
                }
            });

            // specifier modal - accordions
            const accordionBtns = document.querySelectorAll(
                ".js-accordion-button"
            );
            if (accordionBtns) {
                accordionBtns.forEach(btn => {
                    btn.addEventListener("click", () => {
                        btn.classList.toggle("active");
                        const panel = btn.previousElementSibling;
                        if (panel.style.maxHeight) {
                            panel.style.maxHeight = null;
                            btn.innerHTML =
                                'View more<span class="plus"></span>';
                        } else {
                            panel.style.maxHeight = panel.scrollHeight + "px";
                            btn.innerHTML =
                                'View less<span class="minus"></span>';
                        }
                    });
                });
            }

            // reinit carousels
            const carousels = document.querySelectorAll("[data-carousel]");
            if (carousels) carousels.forEach(carouselInit);

            // reinit collection functionality
            window.collectionBar.load();
            const interactivePanelEl = document.querySelector(
                ".js-interactive-panel"
            );
            if (interactivePanelEl) {
                document.body.classList.add("modal-with-collection");
                interactivePanel(interactivePanelEl);
            }

            shareBtn();
        });
    });
};

export const hideModal = () => {
    const imageModal = document.querySelectorAll(".modal");

    closeNotification();

    if (imageModal) {
        const last = imageModal[imageModal.length - 1];

        if (last && last.classList.contains("image-modal")) {
            last.remove();
        } else {
            document.body.classList.remove("show-modal");
            history.replaceState(null, null, " ");
        }
    } else {
        document.body.classList.remove("show-modal");
        history.replaceState(null, null, " ");
    }

    // reset collection bar
    window.collectionBar.unSelectProducts();
    document.body.classList.remove("image-modal");
    document.body.classList.remove("select-mode-active");
    document.body.classList.remove("modal-with-collection");
};

document.body.addEventListener("keyup", e => {
    if (e.keyCode == 27) {
        hideModal();
    }
});

export const autoShowModal = () => {
    if (window.location.hash) {
        const itemId = window.location.hash.split("#")[1];
        if (
            window.location.pathname.indexOf("species") !== -1 &&
            window.location.pathname.indexOf("colours") !== -1 &&
            itemId.indexOf("page") === -1
        ) {
            if (itemId.indexOf("image") !== -1) {
                const imageId = window.location.hash.split("image-")[1];
                showModal("image", imageId);
            } else {
                showModal("product", itemId);
            }
        }
        if (
            window.location.pathname.indexOf("showcase") !== -1 &&
            !document.body.classList.contains("show-modal")
        ) {
            showModal("showcase", itemId);
        }
        if (window.location.pathname.indexOf("technical-resources") !== -1) {
            showModal("resource", itemId);
        }
        if (
            window.location.pathname.indexOf("collection/overview") !== -1 &&
            itemId !== "page-header"
        ) {
            showModal("specifier", itemId);
        }
    } else {
        hideModal();
    }

    // small modal window from Collection Bar
    let modal = document.getElementById("smallModal");
    let btn = document.getElementById("collection-bar-button");
    let span = document.getElementsByClassName("close-button")[0];
    let btnClose = document.getElementById("closeButton");

    if (modal) {
        const closeButton = document.querySelector("#closeButton");
        const addCart = document.querySelector("#add-to-cart");
        const addCollection = document.querySelector("#add-to-collection");
        const addCartModal = modal.querySelector("#add-to-cart-modal");
        const addCollectionModal = modal.querySelector(
            "#add-to-collection-modal"
        );

        if (addCartModal) {
            addCartModal.addEventListener("click", e => {
                if (addCart) {
                    e.preventDefault();
                    addCartModal.classList.add("loading");

                    if (!addCartModal.classList.contains("success")) {
                        addCart.click();
                    }
                }
            });
        }
        if (addCollectionModal) {
            addCollectionModal.addEventListener("click", e => {
                if (addCollection) {
                    e.preventDefault();
                    addCollectionModal.classList.add("loading");

                    if (!addCollectionModal.classList.contains("success")) {
                        addCollection.click();
                    }
                }
            });
        }
    }

    btn.onclick = function() {
        const samplesBtn = document.querySelector(
            ".button-dark.samples-button:not(.modal-button)"
        );
        if (
            !btn.classList.contains("done") &&
            (document.querySelectorAll(".image-wrapper.selected").length !==
                0 ||
                document.querySelector(".js-interactive-panel"))
        ) {
            modal.style.display = "block";
            document.body.classList.add("select-mode-active");

            if (document.querySelector("#add-to-cart-modal")) {
                document
                    .querySelector("#add-to-cart-modal")
                    .classList.remove("success");
                document.querySelector("#add-to-cart-modal").innerHTML =
                    "<i class='fas fa-shopping-cart'></i><i class='fas fa-spinner fa-spin'></i> Add to sample cart";
            }
            if (document.querySelector("#add-to-collection-modal")) {
                document
                    .querySelector("#add-to-collection-modal")
                    .classList.remove("success");
                document.querySelector("#add-to-collection-modal").innerHTML =
                    "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg><i class='fas fa-spinner fa-spin'></i> Add to collection";
            }

            if (samplesBtn) {
                samplesBtn.innerHTML =
                    "<i class='fas fa-shopping-cart'></i> Cancel swatch selection";
            }
        } else {
            document.body.classList.remove("select-mode-active");
            window.collectionBar.unSelectProducts();
        }
    };
    span.onclick = function() {
        modal.style.display = "none";
        const samplesBtn = document.querySelector(
            ".samples-button.button-dark:not(.modal-button)"
        );
        if (samplesBtn) {
            if (!document.body.classList.contains("select-mode-active")) {
                samplesBtn.innerHTML =
                    "<i class='fas fa-shopping-cart'></i> Select swatches";
            }
        }
        const closeButton = document.querySelector("#closeButton");
        if (closeButton) {
            closeButton.innerHTML =
                'Discard selection <span class="remove-inmobile-inline">and stop selecting</span>';
        }
    };
    btnClose.onclick = function() {
        modal.style.display = "none";
        const samplesBtn = document.querySelector(
            ".samples-button.button-dark:not(.modal-button)"
        );
        if (samplesBtn) {
            if (!document.body.classList.contains("select-mode-active")) {
                samplesBtn.innerHTML =
                    "<i class='fas fa-shopping-cart'></i> Select swatches";
            }
        }
        const closeButton = document.querySelector("#closeButton");
        if (closeButton) {
            closeButton.innerHTML =
                'Discard selection <span class="remove-inmobile-inline">and stop selecting</span>';
        }
    };
    window.onclick = function(event) {
        if (event.target == modal) {
            modal.style.display = "none";
            const samplesBtn = document.querySelector(
                ".samples-button.button-dark:not(.modal-button)"
            );
            if (samplesBtn) {
                if (!document.body.classList.contains("select-mode-active")) {
                    samplesBtn.innerHTML =
                        "<i class='fas fa-shopping-cart'></i> Select swatches";
                }
            }
            const closeButton = document.querySelector("#closeButton");
            if (closeButton) {
                closeButton.innerHTML =
                    'Discard selection <span class="remove-inmobile-inline">and stop selecting</span>';
            }
        }
    };
};

document.addEventListener("DOMContentLoaded", () => {
    shareBtn();
});

document.body.onclick = function(e) {
    if (e.target.classList.contains("share-action")) {
        e.preventDefault();

        closeNotification();

        const shareTooltip = e.target.parentNode.querySelector(
            ".share-tooltip"
        );
        const shareAction = e.target;

        let el = e.target;
        let elClone = el.cloneNode(true);
        el.parentNode.replaceChild(elClone, el);

        if (shareTooltip.classList.contains("is-hidden")) {
            shareTooltip.classList.remove("is-hidden");
            shareAction.classList.add("open");
        } else {
            shareTooltip.classList.add("is-hidden");
            shareAction.classList.remove("open");
        }
    }

    if (e.target.classList.contains("share-close")) {
        e.preventDefault();
        const shareTooltip = e.target.parentNode.parentNode.querySelector(
            ".share-tooltip"
        );
        if (shareTooltip) {
            shareTooltip.classList.add("is-hidden");
        }
    }

    if (e.target.dataset.copyLink != undefined) {
        e.preventDefault();
        copyTextToClipboard(e.target.dataset.copyLink);
        e.target.parentNode.classList.add("is-hidden");
        copyLink.parentNode.parentNode.classList.add("is-hidden");
    }

    if (e.target.classList.contains("share-notification-dismiss")) {
        e.preventDefault();
        closeNotification();
    }
};
