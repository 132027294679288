export default class Swatches {
    constructor() {
        this.swatches = document.querySelectorAll(".product-swatch");
    }

    load() {
        window.swatchClick = this._swatchClick;

        for (let swatch of this.swatches) {
            if (swatch) {
                swatch.addEventListener(
                    "click",
                    this._onCheckboxClick.bind(this)
                );
            }
        }

        const selectProducts = document.querySelectorAll(".select-group");

        if (selectProducts) {
            selectProducts.forEach(c => {
                c.addEventListener("click", e => {
                    c.parentNode.click();
                });
            });
        }
    }

    _swatchClick(e, id) {
        e.preventDefault();
        showModal("product", id);
    }

    _onCheckboxClick(e) {
        e.stopPropagation();
        let selectButton = e.target.querySelector(".select-group");

        if (selectButton) {
            const samplesBtn = document.querySelector(
                ".button-dark.samples-button:not(.modal-button)"
            );
            const addToCartButton = document.getElementById("add-to-cart");
            const addToCollectionButton = document.getElementById(
                "add-to-collection"
            );

            let checkbox = selectButton.querySelector("input");
            let productSwatch = selectButton.parentElement;
            let productId = productSwatch.dataset.productId;
            let productSKU = productSwatch.dataset.productSku;

            selectButton.classList.toggle("selected");

            if (checkbox) {
                checkbox.checked = !checkbox.checked;
            }

            if (productSwatch) {
                productSwatch.classList.toggle("selected");
            }

            if (
                document.querySelectorAll(".product-swatch.selected").length !==
                0
            ) {
                const collectionBarButton = document.querySelector(
                    ".collection-bar-button"
                );

                document.body.classList.add("select-mode-active");

                if (addToCollectionButton) {
                    addToCollectionButton.classList.remove("success");
                    addToCollectionButton.classList.remove("noswatches");
                }

                if (addToCartButton) {
                    addToCartButton.classList.remove("success");
                    addToCartButton.classList.remove("noswatches");
                }

                if (collectionBarButton) {
                    collectionBarButton.innerHTML = "Cancel";
                    collectionBarButton.classList.remove("done");
                    samplesBtn.classList.remove("done");
                }
            } else {
                document.body.classList.remove("select-mode-active");
            }

            if (checkbox.checked) {
                window.stagedProducts.add(
                    JSON.stringify({
                        sku: productSKU,
                        quantity: 1,
                        id: productId
                    })
                );
                addToCollectionButton.classList.remove("noswatches");
            } else {
                window.stagedProducts.delete(
                    JSON.stringify({
                        sku: productSKU,
                        quantity: 1,
                        id: productId
                    })
                );
            }

            if (document.body.className.match("select-mode-active")) {
                if (samplesBtn) {
                    samplesBtn.innerHTML =
                        "<i class='fas fa-shopping-cart'></i> Cancel swatch selection";
                }

                addToCollectionButton.classList.remove("noswatches");
                addToCollectionButton.innerHTML =
                    "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> Add to collection";

                addToCartButton.classList.remove("noswatches");
                addToCartButton.innerHTML =
                    "<i class='fas fa-shopping-cart'></i><i class='fas fa-spinner fa-spin'></i> Add to sample cart";
            } else {
                if (samplesBtn) {
                    samplesBtn.innerHTML =
                        "<i class='fas fa-shopping-cart'></i> Select swatches";
                }
                addToCollectionButton.classList.add("noswatches");
                addToCollectionButton.innerHTML =
                    "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> No swatches selected <span class='feature-tooltip'>To add samples to the collection, you must first select them by clicking on the swatches below.</span>";
                addToCartButton.classList.add("noswatches");
                addToCartButton.innerHTML =
                    "<i class='fas fa-shopping-cart'></i> No swatches selected <span class='feature-tooltip'>To add samples to the sample cart, you must first select them by clicking on the swatches below.</span>";
            }

            // if (
            //     document.querySelectorAll(".image-wrapper.selected").length ===
            //     0
            // ) {
            //     if (samplesBtn) {
            //         samplesBtn.innerHTML =
            //             "<i class='fas fa-shopping-cart'></i> Select swatches";
            //     }
            //     addToCollectionButton.classList.add("noswatches");
            //     addToCollectionButton.innerHTML =
            //         "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> No swatches selected <span class='feature-tooltip'>To add samples to the collection, you must first select them by clicking on the swatches below.</span>";
            //     addToCartButton.classList.add("noswatches");
            //     addToCartButton.innerHTML =
            //         "<i class='fas fa-shopping-cart'></i> No swatches selected <span class='feature-tooltip'>To add samples to the sample cart, you must first select them by clicking on the swatches below.</span>";
            // }
        }
    }
}
