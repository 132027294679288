// usage
/*
<div class="carousel-wrapper" data-carousel>
    <div class="js-carousel-slides carousel-slides">
        <div class="slide js-slide"></div>
        <div class="slide js-slide"></div>
        <div class="slide js-slide"></div>
        <div class="slide js-slide"></div>
    </div>
    <div class="js-carousel-controls carousel-controls">
        <button class="carousel-prev js-prev">Previous</button>
        <ol class="carousel-dots">
            <li class="dot js-dot">1</li>
            <li class="dot js-dot">2</li>
            <li class="dot js-dot">3</li>
            <li class="dot js-dot">4</li>
        </ol>
        <button class="carousel-next js-next">Next</button>
    </div>
</div>
*/
function carouselInit(el) {
    // get elements
    const slides = el.querySelectorAll(".js-slide");
    const dots = el.querySelectorAll(".js-dot");
    const prev = el.querySelector(".js-prev");
    const next = el.querySelector(".js-next");

    // numbers
    const totalSlides = slides.length;
    let currentSlide = 0;

    // init
    slides.forEach(slide => slide.classList.remove("active"));
    slides[0].classList.add("active");

    dots.forEach(dot => dot.classList.remove("active"));
    dots[0].classList.add("active");

    // slide function
    const goToSlide = n => {
        slides.forEach(slide => slide.classList.remove("active"));
        dots.forEach(dot => dot.classList.remove("active"));

        currentSlide = (n + totalSlides) % totalSlides;

        slides[currentSlide].classList.add("active");
        dots[currentSlide].classList.add("active");
    };

    // click event
    prev.addEventListener("click", () => goToSlide(currentSlide - 1));
    next.addEventListener("click", () => goToSlide(currentSlide + 1));

    dots.forEach(dot => {
        dot.addEventListener("click", function(e) {
            goToSlide([...e.target.parentNode.children].indexOf(e.target));
        });
    });

    // key event
    el.addEventListener("keyup", function(e) {
        if (e.keyCode === 37) goToSlide(currentSlide - 1);
        if (e.keyCode === 39) goToSlide(currentSlide + 1);
    });

    // touch event
    let xDown = null;
    let yDown = null;

    const handleTouchStart = e => {
        xDown = e.touches[0].clientX;
        yDown = e.touches[0].clientY;
    };

    const handleTouchMove = e => {
        if (!xDown || !yDown) return;

        let xUp = e.touches[0].clientX;
        let yUp = e.touches[0].clientY;

        let xDiff = xDown - xUp;
        let yDiff = yDown - yUp;

        // detect left and right swipes on the carousel
        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            if (xDiff > 0) {
                // left swipe
                goToSlide(currentSlide + 1);
            } else {
                // right swipe
                goToSlide(currentSlide - 1);
            }
        }

        // reset values
        xDown = null;
        yDown = null;
    };

    el.addEventListener("touchstart", handleTouchStart, false);
    el.addEventListener("touchmove", handleTouchMove, false);
}

export default carouselInit;
