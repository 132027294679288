function header() {
    const open = document.querySelector('.js-menu-open')
    const close = document.querySelector('.js-menu-close')
    open.addEventListener('click', () => document.body.classList.add('nav-open'))
    close.addEventListener('click', () => document.body.classList.remove('nav-open'))

    // sub menus
    const submenuToggle = document.querySelectorAll('.has-submenu')
    if(submenuToggle){
        submenuToggle.forEach((button) => {
            button.addEventListener('click', (e) => { 
                if(window.innerWidth < 700){
                    e.preventDefault()
                    button.parentNode.classList.toggle('submenu-open')
                }
            })
        })
    } 
}

export default header
