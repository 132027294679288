document.addEventListener('DOMContentLoaded', () => {

    function is_touch_device() {
        const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        const mq = function (query) {
            return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
            return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        const query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
    }

    function Scroll() {

        const addToCollectionNS = document.querySelector('#add-to-collection.noswatches')
        const addToCartNS = document.querySelector('#add-to-cart.noswatches')


        if (addToCollectionNS && addToCollectionNS.classList.contains('hover')) {
            addToCollectionNS.classList.remove('hover')
            addToCollectionNS.querySelector('.feature-tooltip').style.display = 'none'
        }

        if (addToCartNS && addToCartNS.classList.contains('hover')) {
            addToCartNS.classList.remove('hover')
            addToCartNS.querySelector('.feature-tooltip').style.display = 'none'
        }
    }

    function unScroll(el) {
        if (is_touch_device()) {
            el.addEventListener('touchstart', () => {
                if (el === document.querySelector('#add-to-cart.noswatches')) {
                    document.querySelector('#add-to-collection.noswatches .feature-tooltip').style.display = 'none'
                } else {
                    if(document.querySelector('#add-to-cart.noswatches .feature-tooltip')) {
                        document.querySelector('#add-to-cart.noswatches .feature-tooltip').style.display = 'none'
                    }
                }
                el.classList.add('hover')
                el.querySelector('.feature-tooltip').style.display = 'block'
            })
        } else {
            el.addEventListener('mouseover', () => {
                if (el === document.querySelector('#add-to-cart.noswatches')) {
                    document.querySelector('#add-to-collection.noswatches .feature-tooltip').style.display = 'none'
                } else {
                    if(document.querySelector('#add-to-cart.noswatches .feature-tooltip')) {
                        document.querySelector('#add-to-cart.noswatches .feature-tooltip').style.display = 'none'
                    }
                }

                el.classList.add('hover')
                //el.style.color = '#4D659E'

                if(el.querySelector('.feature-tooltip')) {
                    el.querySelector('.feature-tooltip').style.display = 'block'
                }
            })

            el.addEventListener('mouseout', () => {
                const cartNoSwatches = document.querySelector('#add-to-cart.noswatches')
                const collectionNoSwatches = document.querySelector('#add-to-collection.noswatches')


                if(collectionNoSwatches && collectionNoSwatches.querySelector('.feature-tooltip')) {
                    collectionNoSwatches.querySelector('.feature-tooltip').style.display = 'none'
                }
                
                if(cartNoSwatches && cartNoSwatches.querySelector('.feature-tooltip')) {
                    cartNoSwatches.querySelector('.feature-tooltip').style.display = 'none'
                }
                el.classList.remove('hover')
            })
        }
    }

    unScroll(document.querySelector('#add-to-cart.noswatches'))
    unScroll(document.querySelector('#add-to-collection.noswatches'))

    document.addEventListener("touchmove", Scroll, false)
    document.addEventListener("scroll", Scroll, false)

    document.body.addEventListener('touchstart', function(e){
        if(!e.target.classList.contains('feature-tooltip') && !e.target.classList.contains('noswatches')) {
            if(document.querySelector('#add-to-collection.noswatches .feature-tooltip')) {
                document.querySelector('#add-to-collection.noswatches .feature-tooltip').style.display = 'none'
                document.querySelector('#add-to-collection.noswatches').classList.remove('hover')
            }
            if(document.querySelector('#add-to-cart.noswatches .feature-tooltip')) {
                document.querySelector('#add-to-cart.noswatches .feature-tooltip').style.display = 'none'
                document.querySelector('#add-to-cart.noswatches').classList.remove('hover')
            }
        }
    }, false)
})



export default class CollectionBar {

    updateCount() {
        document.querySelector('.product-count').innerHTML = window.stagedProducts.size;
        window.stagedProducts.size == 1 ? document.querySelector('.staged-products-plural').style.display = "none" : document.querySelector('.staged-products-plural').style.display = "inline";
        if (!window.stagedProducts.size) {
            document.body.classList.remove('select-mode-active');
        }
    }

    unSelectProducts() {
        const products = document.querySelectorAll('.select-product')
        const productsInteractive = document.querySelectorAll('[name="product-interactive"]')
        if (products || productsInteractive) {
            if (products) {
                products.forEach((product) => {
                    product.classList.remove('selected')
                    product.parentNode.classList.remove('selected')

                    if (product.querySelector('[type="checkbox"]')) {
                        product.querySelector('[type="checkbox"]').checked = false
                    }
                })
            }

            if (productsInteractive) {
                productsInteractive.forEach((product) => {
                    document.querySelector(`[data-id="${product.id}"]`).classList.remove('selected')
                    product.checked = false
                })
            }

            window.stagedProducts.clear()
            document.querySelector('.product-count').innerHTML = 0

            // remove success states
            const addToCartButton = document.getElementById('add-to-cart')
            const addToCollectionButton = document.getElementById('add-to-collection')
            if (addToCollectionButton) addToCollectionButton.classList.remove('success')
            if (addToCartButton) addToCartButton.classList.remove('success')
        }

        if (document.querySelector('.button-dark.samples-button:not(.modal-button)')) {
            document.querySelector('.button-dark.samples-button:not(.modal-button)').innerHTML = "<i class='fas fa-shopping-cart'></i> Select swatches";
        }
    }

    load() {
        // add to cart button
        const addToCartButton = document.getElementById('add-to-cart');
        if (addToCartButton) {
            this.addToCartButton = addToCartButton;
            addToCartButton.addEventListener('click', this.addToCart.bind(this));
        }

        // add to collection button
        const addToCollectionButton = document.getElementById('add-to-collection');
        if (addToCollectionButton) {
            this.addToCollectionButton = addToCollectionButton;
            addToCollectionButton.addEventListener('click', this.addToCollection.bind(this));
        }

        // add to collection button from collection bar (toggles add/remove)
        const addToCollectionButtonBar = document.getElementById('add-remove-from-collection');
        if (addToCollectionButtonBar) {
            this.addToCollectionButtonBar = addToCollectionButtonBar;
            addToCollectionButtonBar.addEventListener('click', this.addRemoveFromCollection.bind(this));
        }

        // close collection bar
        const closeCollectionBar = document.getElementById('collection-bar-button');
        if (closeCollectionBar) {
            this.closeCollectionBar = closeCollectionBar;
            closeCollectionBar.addEventListener('click', (e) => {
                e.preventDefault()
                
                const swatchCount = document.querySelector('.swatch-count')

                if (swatchCount) {
                    const interactivePanelEl = document.querySelector('.js-interactive-panel');
                    if (interactivePanelEl) {
                        const checkedLength = document.querySelectorAll('[name="product-interactive"]:checked').length
                        swatchCount.innerHTML = `${checkedLength} ${(checkedLength === 1) ? ' swatch' : ' swatches'}`
                    } else {
                        const checkedLength2 = document.querySelectorAll('.image-wrapper.selected').length
                        swatchCount.innerHTML = `${checkedLength2} ${(checkedLength2 === 1) ? ' swatch' : ' swatches'}`
                    }
                }
            });
        }

        const closeButton = document.querySelector('#closeButton')

        if (closeButton) {
            closeButton.addEventListener('click', e => {
                e.preventDefault()
                document.body.classList.remove('select-mode-active')
                this.unSelectProducts()
            })
        }

        // toggle collection bar
        const samplesBtn = document.querySelector('.button-dark.samples-button:not(.modal-button)')
        if (samplesBtn) {
            this.samplesBtn = samplesBtn;
            samplesBtn.addEventListener('click', (e) => {
                e.preventDefault()

                if (!document.body.classList.contains('select-mode-active')) {
                    this.addToCartButton.innerHTML = "<i class='fas fa-shopping-cart'></i> No swatches selected <span class='feature-tooltip'>To add samples to the sample cart, you must first select them by clicking on the swatches below.</span>";
                    this.addToCollectionButton.innerHTML = "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg> No swatches selected <span class='feature-tooltip'>To add samples to the collection, you must first select them by clicking on the swatches below.</span>";

                    this.addToCartButton.classList.add('noswatches')
                    this.addToCollectionButton.classList.add('noswatches')

                    document.querySelector('.colour-list-controls').scrollIntoView({ behavior: 'smooth', block: 'start' })
                    samplesBtn.innerHTML = "<i class='fas fa-shopping-cart'></i> Cancel swatch selection";
                    document.body.classList.add('select-mode-active')
                } else {
                    if (!samplesBtn.classList.contains('done')) {
                        const modal = document.getElementById('smallModal')
                        modal.style.display = "block"
                        if (document.querySelector('#add-to-cart-modal')) {
                            document.querySelector('#add-to-cart-modal').classList.remove('success')
                            document.querySelector('#add-to-cart-modal').innerHTML = "<i class='fas fa-shopping-cart'></i><i class='fas fa-spinner fa-spin'></i> Add to sample cart";
                        }
                        if (document.querySelector('#add-to-collection-modal')) {
                            document.querySelector('#add-to-collection-modal').classList.remove('success')
                            document.querySelector('#add-to-collection-modal').innerHTML = "<svg class='collection-icon'><use xlink:href='#collection-icon'></svg><i class='fas fa-spinner fa-spin'></i> Add to collection";
                        }
                    } else {
                        samplesBtn.innerHTML = "<i class='fas fa-shopping-cart'></i> Select swatches";
                        document.body.classList.remove('select-mode-active')
                        this.unSelectProducts()
                    }
                }

            });
        }
    }

    addToCart() {
        if (!this.addToCartButton.classList.contains('success') && !this.addToCartButton.classList.contains('noswatches')) {
            this.addToCartButton.classList.add('loading');
            this.addToCartButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Add to sample cart';

            window.stagedProducts.forEach(product => {
                const productObject = JSON.parse(product);
                delete productObject.id;

                fetch('/umbraco/surface/cart/savecartitem', {
                    method: "POST",
                    body: JSON.stringify(productObject),
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                    .then(res => {
                        const samplesBtn = document.querySelector('.button-dark.samples-button:not(.modal-button)');
                        const collectionBarButton = document.querySelector('.collection-bar-button');

                        if (samplesBtn) {
                            samplesBtn.innerHTML = "<i class='fas fa-shopping-cart'></i> Finish swatch selection";
                        }

                        collectionBarButton.innerHTML = "Done";
                        collectionBarButton.classList.add('done');
                        this.addToCartButton.classList.remove('loading');
                        this.addToCartButton.classList.add('success');
                        this.addToCartButton.innerHTML = '<span class="fas fa-check">&nbsp;</span> <span class="success-only">Added to sample cart</span>';
                        document.querySelector('.js-cart-count').innerHTML = res.TotalCartCount;

                        // Unselecting items after adding to cart
                        this.unselect();

                        return res.json();
                    })
                    .then(res => {
                        const cartCount = [
                            ...document.querySelectorAll(".js-cart-count")
                        ];
                        cartCount.forEach(cc => {
                            cc.innerHTML = res.TotalCartCount;
                        });
                    });
            });

            window.stagedProducts.clear();
        }
    }

    // Function to unselect items
    unselect() {
        let selectedItems = document.querySelectorAll(".selected");
        selectedItems.forEach(item => {
            item.classList.remove("selected");
        });
    }

    addToCollection() {
        
        if (!this.addToCollectionButton.classList.contains('success') && !this.addToCollectionButton.classList.contains('noswatches')) {
            this.addToCollectionButton.classList.add('loading');
            this.addToCollectionButton.innerHTML = '<i class="fas fa-spinner fa-spin"></i> Add to collection';

            window.stagedProducts.forEach(product => {
                const productObject = JSON.parse(product);
                if(productObject.id.includes('-')) {
                    window.collection.add('products', productObject.id.split('-')[1])
                } else {
                    window.collection.add('products', productObject.id)
                }
            })

            window.collection.save().then((res) => {
                const samplesBtn = document.querySelector('.button-dark.samples-button:not(.modal-button)');
                const collectionBarButton = document.querySelector('.collection-bar-button')
                const closeButton = document.querySelector('#closeButton')
                const modal = document.getElementById('smallModal');

                if (samplesBtn) {
                    samplesBtn.innerHTML = "<i class='fas fa-shopping-cart'></i> Finish swatch selection";
                    samplesBtn.classList.add('done');
                }

                collectionBarButton.innerHTML = "Done";
                collectionBarButton.classList.add('done');
                collection.state.id = res;

                document.cookie = "collection=" + res + "; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/"
                localStorage.setItem('nzpg_collection', JSON.stringify(collection.state))

                this.addToCollectionButton.classList.remove('loading');
                this.addToCollectionButton.classList.add('success');
                this.addToCollectionButton.innerHTML = '<span class="fas fa-check">&nbsp;</span> <span class="success-only">Added to collection</span>';

                if (modal) {
                    const addCollectionModal = modal.querySelector('#add-to-collection-modal')

                    if (addCollectionModal) {
                        addCollectionModal.classList.add('success')
                        addCollectionModal.classList.remove('loading')
                        addCollectionModal.innerHTML = '<span class="fas fa-check">&nbsp;</span> <span class="success-only">Added to collection</span>';
                    }

                    if (closeButton) {
                        closeButton.innerHTML = 'Done'
                    }
                }
            })
        }
    }

    addRemoveFromCollection() {
        this.addToCollectionButtonBar.classList.add('loading');
        window.stagedProducts.forEach(product => {
            const productObject = JSON.parse(product);
            if (this.addToCollectionButtonBar.classList.contains('success')) {
                window.collection.remove('products', productObject.id);
            } else {
                window.collection.add('products', productObject.id);
            }
        });
        window.collection.save().then((res) => {
            collection.state.id = res;
            document.cookie = "collection=" + res + "; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/";
            localStorage.setItem('nzpg_collection', JSON.stringify(collection.state));
            this.addToCollectionButtonBar.classList.remove('loading');
            if (this.addToCollectionButtonBar.classList.contains('success')) {
                this.addToCollectionButtonBar.classList.remove('success');
            } else {
                this.addToCollectionButtonBar.classList.add('success');
            }
        });
    }

    // addResourceToCollection(resourceId) {
        // console.log(resourceId);
        // this.addResourceToCollectionButton.classList.add('loading');
        // window.collection.add('resources', resourceId);
        // window.collection.save().then((res) => {
        //     collection.state.id = res;
        //     document.cookie = "collection=" + res + "; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/";
        //     localStorage.setItem('nzpg_collection', JSON.stringify(collection.state));
        //     this.addResourceToCollectionButton.classList.remove('loading');
        //     this.addResourceToCollectionButton.classList.add('success');
        // });
    // }

}


