import carouselInit from "./carousel.js";
import loadCollectionListeners from "../common/showcasePage";

export default function loadMorePage() {
    const container = document.querySelector(".js-load-more-container");
    const categorySelect = document.querySelector("#categories");
    const searchField = document.querySelector("#live-search");
    
    let loadMore = document.querySelector(".js-load-more");
    let i = 0;
    let next = 6;
    let endpoint;

    switch (loadMore.dataset.type) {
        case "news":
            endpoint = "/umbraco/surface/paging/articles?brandId=0&";
            i = 0;
            next = 3;
            break;
        case "showcase":
            endpoint = "/umbraco/surface/paging/showcase?brandId=0&";
            i = 0;
            next = 6;
            break;
    }

    const getCount = url => {
        fetch(url).then(response => {
            response.text().then(html => {
                loadMore = document.querySelector(".js-load-more");
                if (!html.length && loadMore) {
                    loadMore.style.display = "none";
                } else {
                    loadMore.style.display = "inline-block";
                }
            });
        });
    };

    const getItems = (url, loadOnClick = false) => {
        fetch(url).then(response => {
            if (loadOnClick) {
                loadMore.parentNode.classList.add("loading");
            }

            response.text().then(html => {
                if (loadOnClick) {
                    container.innerHTML += html;
                } else {
                    container.innerHTML = "";
                    container.innerHTML =
                        html === ""
                            ? '<div class="news-list-item"><h3 class="news-heading">No items found...</h3></div>'
                            : html;
                }

                loadMore.parentNode.classList.remove("loading");

                // reinit carousels
                const carousels = document.querySelectorAll("[data-carousel]");

                if (carousels) {
                    carousels.forEach(carouselInit);
                }

                // icon buttons
                window.loadCollectionListeners = loadCollectionListeners;
                window.loadCollectionListeners();
            });
        });
    };

    if (loadMore) {
        getItems(
            `${endpoint}s=${searchField.value}&skip=0&take=${next}&categoryId=${
                categorySelect.value
            }`
        );
        getCount(
            `${endpoint}s=${
                searchField.value
            }&skip=${next}&take=${next}&categoryId=${categorySelect.value}`
        );
    }

    loadMore.addEventListener("click", () => {
        i += next;
        loadMore = document.querySelector(".js-load-more");
        if (loadMore) {
            loadMore.style.display = "inline-block";
        }

        getItems(
            `${endpoint}s=${
                searchField.value
            }&skip=${i}&take=${next}&categoryId=${categorySelect.value}`,
            true
        );
        getCount(
            `${endpoint}s=${searchField.value}&skip=${i +
                next}&take=${next}&categoryId=${categorySelect.value}`
        );
    });

    categorySelect.addEventListener("change", () => {
        i = next;
        getItems(
            `${endpoint}s=${searchField.value}&skip=0&take=${next}&categoryId=${
                categorySelect.value
            }`
        );
        getCount(
            `${endpoint}s=${
                searchField.value
            }&skip=${next}&take=${next}&categoryId=${categorySelect.value}`
        );
    });

    searchField.addEventListener("keyup", () => {
        i = next;
        getItems(
            `${endpoint}s=${searchField.value}&skip=0&take=${next}&categoryId=${
                categorySelect.value
            }`
        );
        getCount(
            `${endpoint}s=${
                searchField.value
            }&skip=${next}&take=${next}&categoryId=${categorySelect.value}`
        );
    });
}
