export default function sampleOrderCart() {
    const toggleTitle = document.querySelector('.toggle-title');
    const toggleClose = document.querySelector('.toggle-close');
    const toggleSection = document.querySelector('.toggle-section');
    const empty = document.querySelector('.empty-cart');
    const removeFromCart = document.querySelectorAll('.remove-from-cart');
    const switchType = document.querySelector('.switch-types');
    const updateQuantity = document.querySelectorAll('.update-quantity');

    const cartCount = document.querySelector('.cart-count');

    function updateItemAPI(skuValue, quantityValue) {
        const data = { sku: skuValue, quantity: quantityValue };
        const url = '/umbraco/surface/cart/savecartitem';
        fetch(url, {
            method: 'POST',
            body: JSON.stringify(data),
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(res => {
            return res.json()
        }).then(res => {
            document.querySelector('.js-cart-count').innerHTML = res.TotalCartCount;
        });
    }

    function removeAll(el) {
        updateItemAPI(el.dataset.sku, 0);

        const li = document.querySelector(`li[data-id="${el.dataset.sku}"]`);

        if (li) {
            li.remove();
        }
    }

    if (updateQuantity) {
        updateQuantity.forEach(input => {
            input.addEventListener('change', function () {
                fetch("/umbraco/surface/cart/savecartitem", {
                    method: "POST",
                    body: JSON.stringify({
                        sku: this.dataset.sku,
                        quantity: this.value
                    }),
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        document.querySelector(".js-cart-count").innerHTML = res.TotalCartCount;
                    });
            });
        });
    }


    if (toggleTitle) {
        toggleTitle.addEventListener('click', (e) => {
            e.preventDefault();

            toggleSection.classList.toggle('is-hidden');
            toggleTitle.classList.toggle('is-hidden');
            toggleClose.classList.toggle('is-hidden');
        });

        toggleClose.addEventListener('click', (e) => {
            e.preventDefault();

            toggleSection.classList.toggle('is-hidden');
            toggleTitle.classList.toggle('is-hidden');
            toggleClose.classList.toggle('is-hidden');
        });
    }

    if (empty) {
        empty.addEventListener('click', (e) => {
            e.preventDefault();

            if (removeFromCart) {
                removeFromCart.forEach((item) => {
                    removeAll(item);
                });
            }

            const cart = document.querySelector('.cart-list');
            cart.innerHTML = '<li><h1>Empty sample cart</h1></li>';

            if (document.querySelector('.js-cart-count')) {
                document.querySelector('.js-cart-count').innerHTML = 0;
            } else {
                cartCount.innerHTML = 0;
            }
        });
    }

    if (switchType) {
        const switchBoard = switchType.querySelector('[data-switch-board]')
        const folderBoard = switchType.querySelector('[data-switch-folder]')
        const sizeDropdowns = document.querySelectorAll('.js-sample-size')

        switchBoard.addEventListener('click', () => sizeDropdowns.forEach(dropdown => dropdown.value = 'board'))
        folderBoard.addEventListener('click', () => sizeDropdowns.forEach(dropdown => dropdown.value = 'folder'))

        if (sizeDropdowns) {
            sizeDropdowns.forEach(input => {
                input.addEventListener('change', function () {
                    var productSku = this.dataset.sku;
                    var data = { sku: productSku, quantity: -1 };
                    const url = '/umbraco/surface/cart/savecartitem';

                    //Do this in two steps. Remove the current one.
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    });

                    //Then post with the correct value, adding at the end ,{size}
                    var oldSku = this.dataset.sku.split(",")[0];
                    productSku = oldSku + "," + input.value;

                    //Change this so the quantity is correct.
                    data = { sku: productSku, quantity: +1 };
                    fetch(url, {
                        method: 'POST',
                        body: JSON.stringify(data),
                        credentials: 'same-origin',
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    }).then(res => {
                        return res.json()
                    }).then(res => {
                        document.querySelector('.js-cart-count').innerHTML = res.TotalCartCount;
                    });
                });
            });
        }
    }
    

    if (removeFromCart) {
        removeFromCart.forEach((item) => {
            item.addEventListener('click', function (e) {
                e.preventDefault();

                removeAll(item);
            });
        });
    }
}
