export const addListeners = () => {
    // empty collection
    const emptyCollectionButton = document.getElementById("empty-collection");
    if (emptyCollectionButton) {
        emptyCollectionButton.addEventListener("click", function() {
            document.cookie =
                "collection=" +
                collection.state.id +
                "; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/";
            collection.empty();
            location.reload();
        });
    }

    // remove product
    const products = document.querySelectorAll(".product-swatch");
    for (let product of products) {
        if (product.querySelector(".remove")) {
            product
                .querySelector(".remove")
                .addEventListener("click", function(e) {
                    e.stopPropagation();
                    const element = this.parentElement.parentElement
                        .parentElement;
                    collection.remove("products", element.dataset.id);
                    collection.save();
                    element.remove();
                });
        }
    }

    // remove resource
    const resources = document.querySelectorAll(".resource-item");
    for (let resource of resources) {
        resource
            .querySelector(".remove")
            .addEventListener("click", function(e) {
                e.stopPropagation();
                const element = this.parentElement.parentElement;
                collection.remove("resources", element.dataset.resourceId);
                collection.save();
                element.remove();
            });
    }

    // remove showcase
    const showcases = document.querySelectorAll(".showcase-item");
    for (let showcase of showcases) {
        showcase
            .querySelector(".icon-remove")
            .addEventListener("click", function(e) {
                e.stopPropagation();
                const id = this.dataset.id;
                const element = document.querySelector(`.showcase-item-${id}`);
                collection.remove("showcase", id);
                collection.save();
                element.remove();
            });
    }

    // remove photo
    const photos = document.querySelectorAll(".photo-item");
    for (let photo of photos) {
        photo.querySelector(".remove").addEventListener("click", function(e) {
            e.stopPropagation();
            const element = this.parentElement.parentElement;
            collection.remove("photoUrls", this.dataset.url);
            collection.save();
            element.remove();
        });
    }

    // share collection
    const shareCollectionButton = document.getElementById("share-collection");
    const closeShareOptionsButton = document.getElementById(
        "close-share-options"
    );
    const copyLink = document.querySelector(".js-copy-link");

    if (shareCollectionButton) {
        shareCollectionButton.addEventListener("click", function() {
            document
                .querySelector(".share-collection-wrapper")
                .classList.add("show-options");
                
            if (copyLink && copyLink.dataset.tooltip) {
                copyLink.dataset.tooltip = "Copy link";
            }
        });
    }

    if (closeShareOptionsButton) {
        closeShareOptionsButton.addEventListener("click", function() {
            document
                .querySelector(".share-collection-wrapper")
                .classList.remove("show-options");

            if (copyLink && copyLink.dataset.tooltip) {
                copyLink.dataset.tooltip = "Copy link";
            }
        });
    }

    if (copyLink) {
        copyLink.addEventListener("click", e => {
            e.preventDefault();

            copyLink.nextElementSibling.select();
            document.execCommand("Copy");

            if (copyLink.dataset.tooltip) {
                copyLink.dataset.tooltip = "Link copied";
            }

            const iconTooltip = copyLink.querySelector('.icon-tooltip');
            
            if (iconTooltip) {
                iconTooltip.innerHTML = 'Link copied';
            }
        });
    }
};
