(function () {

    if (typeof window.CustomEvent === "function") return false;

    function CustomEvent(event, params) {
        params = params || { bubbles: false, cancelable: false, detail: undefined };
        var evt = document.createEvent('CustomEvent');
        evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
        return evt;
    }

    CustomEvent.prototype = window.Event.prototype;

    window.CustomEvent = CustomEvent;
})();

export default function productFilters(className) {
    const dropdowns = document.getElementsByClassName(className);
    const swatches = document.getElementsByClassName('product-swatch');
    const swatchGrid = document.getElementsByClassName('swatch-grid')[0];
    const showAllColours = document.getElementById('show-all-colours');

    if (showAllColours) {
        showAllColours.style.display = "none";

        showAllColours.addEventListener('click', (e) => {
            for (let select of dropdowns) {
                select.selectedIndex = 0;
                select.dispatchEvent(new CustomEvent('change'));
            }
        });
    }

    /*
     * {
     *     edges: "21x1",
     *     sizes: "2440x1220",
     * }
     */
    let filterState = {};

    for (let select of dropdowns) {
        
        // set initial filter state
        filterState[select.dataset.filtertype] = select.options[select.selectedIndex].value;

        // bind event to all filter dropdowns
        select.addEventListener('change', (e) => {
            
            // save new filter value to state
            let filterKey = e.target.dataset.filtertype;
            let filterValue = e.target.value;
            filterState[filterKey] = filterValue;

            // assume all are visible
            let swatchCount = 0;
            swatchGrid.classList.remove('empty');
            for (let swatch of swatches) {
                let showSwatch = true;
                swatch.style.display = "block";
                
                if (showAllColours) {
                    showAllColours.style.display = "none";
                }

                // for each filter != 'all'
                for (let key in filterState) {
                    if (filterState.hasOwnProperty(key) && filterState[key] != "all") {

                        // if the swatch's data attribute doesn't containt the filter value
                        if(!swatch.dataset[key] || swatch.dataset[key].indexOf(filterState[key]) === -1) {
                            
                            // hide it
                            swatch.style.display = "none";
                            showSwatch = false;
                            break;
                        }
                    }
                }

                // count for 'Showing xx results'
                if(showSwatch) {
                    swatchCount++;
                }
            }

            // empty state
            document.getElementById('colour-count').innerHTML = swatchCount;
            document.getElementById('colour-count-plural').style.display = "inline";            
            if(swatchCount == 1) {
                document.getElementById('colour-count-plural').style.display = "none";
            }
            if(!swatchCount) {
                swatchGrid.classList.add('empty');

                if (showAllColours) {
                    showAllColours.style.display = "inline";
                }
            }
        })
    }
}
