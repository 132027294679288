export default class ResourceFilters {
    constructor() {
        this.filters = document.querySelectorAll('[name="product-filter"]');
        this.doctypeDropdown = document.getElementById("categories");
        this.doctypeCheckboxes = document.querySelectorAll(
            '[name="edging-option"]'
        );
        this.search = document.getElementById("live-search");
        this.state = {
            productCategories: [],
            documentCheck: [],
            documentType: "all",
            searchTerm: ""
        };
    }

    load() {
        // product category filters
        this.filters.forEach(filter => {
            filter.addEventListener("change", this._onProductChange.bind(this));
            filter.addEventListener("change", () => {
                const someChecked = this._checkIfAtLeastOneIsChecked();
                if (someChecked) {
                    filter.parentNode.classList.add("filter-selected");
                } else {
                    filter.parentNode.classList.remove("filter-selected");
                }
            });
            if (filter.checked) {
                this.state.productCategories.push(filter.value);
            }
        });

        // document type dropdown
        const doctypeDropdownLabel = document.querySelector(
            ".js-custom-dropdown"
        );
        if (doctypeDropdownLabel) {
            doctypeDropdownLabel.addEventListener("click", function() {
                // toggle box
                doctypeDropdownLabel.classList.toggle("active");
            });
            document.addEventListener("click", function(e) {
                // outside click
                if (
                    doctypeDropdownLabel.classList.contains("active") &&
                    !e.target.classList.contains("js-custom-dropdown-click")
                ) {
                    doctypeDropdownLabel.classList.remove("active");
                }
            });
        }
        this.doctypeCheckboxes.forEach(checkbox =>
            checkbox.addEventListener(
                "change",
                this._onDoctypeChange.bind(this)
            )
        );

        // navigate to category
        const categorySearch = document.location.search.split("?category=")[1];
        if (categorySearch !== undefined) {
            for (let i = 0; i < this.doctypeCheckboxes.length; i++) {
                if (this.doctypeCheckboxes[i].value === categorySearch) {
                    // if search query is found in checkbox list
                    this.state.documentCheck.push(categorySearch); // update state array
                    this.doctypeCheckboxes[0].checked = false; // uncheck all checkbox
                    this.doctypeCheckboxes[i].checked = true; // check checkbox
                    if (doctypeDropdownLabel) {
                        // update label
                        doctypeDropdownLabel.classList.add("selected");
                        doctypeDropdownLabel.innerHTML = this.doctypeCheckboxes[
                            i
                        ].dataset.niceValue;
                    }
                    this._filter();
                }
            }
        }

        // live search
        this.search.addEventListener("keyup", this._onSearch.bind(this));
    }

    _checkIfAtLeastOneIsChecked() {
        const items = this.filters;
        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            if (element.checked) {
                return true;
            }
        }
        return false;
    }

    _onDoctypeChange(e) {
        const checkbox = e.target;
        const value = checkbox.value;
        const doctypeDropdownLabel = document.querySelector(
            ".js-custom-dropdown"
        );
        if (checkbox.value === "all") {
            // if all is checked, uncheck everything else and empty array
            this.doctypeCheckboxes.forEach(check => (check.checked = false));
            this.state.documentCheck = [];
            checkbox.checked = true;
        } else {
            // otherwise uncheck all checkbox
            this.doctypeCheckboxes.forEach(check => {
                if (check.value === "all") {
                    check.checked = false;
                }
            });
            if (checkbox.checked) {
                // if checked, add to array (make sure it isn't already in the array)
                if (!this.state.documentCheck.includes(value)) {
                    this.state.documentCheck.push(value);
                }
            } else {
                // otherwise remove it from array
                this.state.documentCheck.splice(
                    this.state.documentCheck.indexOf(value),
                    1
                );
            }
        }
        if (doctypeDropdownLabel) {
            // update dropdown label
            if (this.state.documentCheck.length) {
                // if array has something
                doctypeDropdownLabel.classList.add("selected");
                if (this.state.documentCheck.length === 1) {
                    // if 1 item in array, show title
                    this.doctypeCheckboxes.forEach(check => {
                        if (check.value === this.state.documentCheck[0]) {
                            doctypeDropdownLabel.innerHTML =
                                check.dataset.niceValue;
                        }
                    });
                }
                if (this.state.documentCheck.length > 1) {
                    // if more than 1 item, show number of items in title
                    doctypeDropdownLabel.innerHTML = `${
                        this.state.documentCheck.length
                    } options selected`;
                }
            } else {
                // otherwise revert to default state
                doctypeDropdownLabel.classList.remove("selected");
                doctypeDropdownLabel.innerHTML = "Any option";
            }
        }
        this._filter();
    }

    _onSearch() {
        this.state.searchTerm = this.search.value.toUpperCase();
        this._filter();
    }

    _onProductChange(e) {
        let product = e.target.value;
        if (e.target.checked) {
            if (!this.state.productCategories.includes(product)) {
                this.state.productCategories.push(product);
            }
        } else {
            this.state.productCategories.splice(
                this.state.productCategories.indexOf(product),
                1
            );
        }
        this._filter();
    }

    _filter() {
        const resources = document.getElementsByClassName("grid-list-item");
        const filterSelected = [
            ...document.querySelectorAll(`input[name="product-filter"]:checked`)
        ];

        for (let resource of resources) {
            const category = resource.dataset.productcategory;
            const documenttype = resource.dataset.documenttype;
            const value = resource
                .querySelector(".heading")
                .innerHTML.toUpperCase();
            resource.style.display = "block";

            let flag = false;
            
            if (resource.dataset.productcategory.length === 0) {
                resource.style.display = "none";
            } else {
                this.state.productCategories.map(pc => {
                    if (category.trim().includes(pc.trim())) {
                        flag = true;
                    }
                });
            }

            if (flag || filterSelected.length === 0) {
                resource.style.display = "block";
            } else {
                resource.style.display = "none";
            }

            if (
                documenttype != "all" &&
                this.state.documentCheck.length &&
                this.state.documentCheck.indexOf(documenttype) === -1
            ) {
                resource.style.display = "none";
            }

            if (value !== "" && value.indexOf(this.state.searchTerm) === -1) {
                resource.style.display = "none";
            }
        }
    }
}
