export default class Collection {

    constructor() {

        this.state = {
            id: null,
            products: [],
            photos: [],
            resources: [],
            showcase: [],
            photoUrls: [],
        };

        this.saveURL = '/umbraco/api/collection/save';
    }

    _removeFromArray(array, value) {
        if(array.includes(value)) {
            const position = array.indexOf(value);
            array.splice(position, 1);
        }
        return array;
    }

    _validate(type) {
        if(!(this.state[type] instanceof Array)) {
            // console.log(type + ' is not a valid collection type.');
            return false;
        }
        return true;
    }

    _post() {
        return fetch(this.saveURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.state),
        }).then(res => {
            return res.json();
        });
    }

    _get() {

    }

    load() {
        const savedState = JSON.parse(localStorage.getItem('nzpg_collection'));
        for(let key in savedState) {
            this.state[key] = savedState[key];
        }
        return this.state;
    }

    updateCount() {
        const countElement = [...document.querySelectorAll('.js-collection-count')];
        //const countElement = document.getElementsByClassName('js-collection-count')[0];
        const sum = this.state.photos.length + this.state.resources.length + this.state.showcase.length + this.state.products.length + this.state.photoUrls.length;
        countElement.map( (el) => {
            el.innerHTML = sum;
        });
    }

    save() {
        this.updateCount();
        localStorage.setItem('nzpg_collection', JSON.stringify(this.state));
        return this._post();
    }

    clear() {
        this.constructor();
        localStorage.removeItem('nzpg_collection');
        return localStorage;
    }

    add(type, id) {
        if(this._validate(type)) {
            if(id instanceof Array) {
                id.forEach(idItem => {
                    this.add(type, idItem);
                })
            }
            else {
                if(!this.state[type].includes(id)) { // avoid duplicates
                    this.state[type].push(id);
                }
            }
            return this.state;
        }
        return false;
    }

    remove(type, id) {
        if(this._validate(type)) {
            this.state[type] = this._removeFromArray(this.state[type], id);
            return this.state;
        }
        return false;
    }

    empty() {
        this.constructor();
        this.save();
        return true;
    }

}
