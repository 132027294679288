export default function loadCollectionListeners() {

    const addToCollection = (el, type, data) => {
        if (!el.classList.contains('success')) {
            el.classList.add('loading')
            window.collection.add(type, el.dataset[data])
            window.collection.save().then((res) => {
                collection.state.id = res
                document.cookie = "collection=" + res + "; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/"
                el.classList.remove('loading')
                el.classList.add('success')
                el.setAttribute("disabled", "disabled")
            })
        }
    }

    // add showcase to collection event binding
    const addShowcaseToCollectionButtons = document.getElementsByClassName('add-showcase-to-collection');
    if (addShowcaseToCollectionButtons) {
        for (let button of addShowcaseToCollectionButtons) {
            button.addEventListener('click', function (e) {
                e.preventDefault()
                addToCollection(this, 'showcase', 'showcaseId')
            });
        };
    }

    // add photo to collection event binding
    const addPhotoToCollectionButtons = document.getElementsByClassName('add-photo-to-collection');
    if (addPhotoToCollectionButtons) {
        for (let button of addPhotoToCollectionButtons) {
            button.addEventListener('click', function (e) {
                e.preventDefault()
                addToCollection(this, 'photoUrls', 'photoUrl')
            });
        };
    }

    // add product to collection event binding
    const addProductToCollection = document.getElementsByClassName('add-product-to-collection')
    if (addProductToCollection) {
        for (let button of addProductToCollection) {
            button.addEventListener('click', function (e) {
                e.preventDefault()
                addToCollection(this, 'products', 'productId')
            });
        };
    }

    // share showcase
    const shareButtons = document.getElementsByClassName('share-showcase');
    if (shareButtons) {
        for (let button of shareButtons) {
            button.addEventListener('click', function () {
                this.parentElement.classList.add('show-options')
            })

            button.parentNode.parentNode.parentNode.addEventListener('mouseout', function (event) {
                //this is the original element the event handler was assigned to
                var e = event.toElement || event.relatedTarget;

                //check for all children levels (checking from bottom up)
                while (e && e.parentNode && e.parentNode != window) {
                    if (e.parentNode == this || e == this) {
                        if (e.preventDefault) e.preventDefault()
                        return false
                    }
                    e = e.parentNode
                }

                button.parentNode.classList.remove('show-options')
            }, true)
        }
    }
    const closeShareButtons = document.getElementsByClassName('close-share-options');
    if (closeShareButtons) {
        for (let button of closeShareButtons) {
            button.addEventListener('click', function () {
                this.parentElement.parentElement.parentElement.classList.remove('show-options')
            })
        }
    }
}
