// open search modal
Array.prototype.slice
  .call(
    document.querySelectorAll('[href="/search/"]')
  )
  .forEach(link => {
    link.addEventListener("click", function(e) {
      e.preventDefault();
      showModal("search", "search");
    });
  });

if (document.querySelector("#show-search-modal")) {
  document
    .querySelector("#show-search-modal")
    .addEventListener("click", function(e) {
      e.preventDefault();
      showModal("search", "search", "show-advanced");
    });
}
   
// advanced search
document.body.addEventListener("click", function(e) {
  if (e.target) {
    if (
      e.target.id === "show-hide-search-filters" ||
      e.target.classList.contains("toggle-section")
    ) {
      e.preventDefault();

      if (e.target.classList.contains("active")) {
        e.target.classList.remove("active");
      } else {
        e.target.classList.add("active");
      }
    }

    if (e.target.id === "show-hide-search-filters") {
      const advancedSearchFilters = document.querySelectorAll(
        "#advanced-search-filters"
      );

      if (advancedSearchFilters) {
        advancedSearchFilters.forEach(advanced => {
          if (advanced.classList.contains("remove")) {
            advanced.classList.remove("remove");
          } else {
            advanced.classList.add("remove");
          }
        });
      }
    }

    if (e.target.id === "reset-filters") {
      e.target.parentNode.classList.remove("reset-active");

      const filterProducts = document.querySelector("#FilterProducts");
      const filterResources = document.querySelector("#FilterResources");
      const filterInspiration = document.querySelector("#FilterInspiration");

      const selects = document.querySelectorAll(".search-filter.selected");

      if (selects) {
        selects.forEach(selects => {
          selects.classList.remove("selected");

          if (selects.querySelector("input")) {
            selects.querySelector("input").removeAttribute("checked");
          }
        });
      }

      if (filterProducts) {
        filterProducts.setAttribute("checked", "checked");
        filterProducts.parentNode.classList.add("selected");
      }

      if (filterResources) {
        filterResources.setAttribute("checked", "checked");
        filterResources.parentNode.classList.add("selected");
      }

      if (filterInspiration) {
        filterInspiration.setAttribute("checked", "checked");
        filterInspiration.parentNode.classList.add("selected");
      }

      document
        .querySelectorAll("[data-parent-category-id]")
        .forEach(item => (item.style.display = "none")); // hide all sub sections
    }

    if (e.target.classList.contains("toggle-section")) {
      e.target.parentNode.parentNode.classList.toggle("hide-filters");
    }
  }
});

// function to see if anything is checked
function checkChecked(el) {
  const formArr = el.form.elements;
  const filterProducts = document.querySelector("#FilterProducts");
  const filterResources = document.querySelector("#FilterResources");
  const filterInspiration = document.querySelector("#FilterInspiration");

  if (
    !(
      filterProducts.checked &&
      filterResources.checked &&
      filterInspiration.checked
    )
  ) {
    return true;
  }

  if (!el.classList.contains("js-all-checkbox")) {
    for (let i = 0; i < formArr.length; i++) {
      const element = formArr[i];
      if (element.type === "radio" || element.type === "checkbox") {
        if (element.checked) {
          return true;
        }
      }
    }
  }
  return false;
}

document.body.addEventListener("change", function(e) {
  const el = e.target;

  if (document.getElementById("reset-filters")) {
    const resetFilterBtn = document.getElementById("reset-filters").parentNode;

    if (checkChecked(el)) {
      resetFilterBtn.classList.add("reset-active");
    } else {
      resetFilterBtn.classList.remove("reset-active");
    }
  }

  if (el && el.parentNode.classList.contains("search-filter")) {
    // target show results for: checkboxes
    const section = document.getElementById(el.dataset.value);
    const li = el.parentElement.classList;

    if (el.type === "checkbox") {
      if (el.checked) {
        li.add("selected");
        el.value = true;
        el.setAttribute("checked", "checked");

        if (section) {
          section.style.display = "block";
        }
      } else {
        li.remove("selected");
        el.checked = false;
        el.value = false;
        el.removeAttribute("checked");

        if (section) {
          section.style.display = "none";
        }
      }
    } else {
      el.parentNode.parentNode.querySelectorAll(".selected").forEach(node => {
        node.classList.remove("selected");
        node.querySelector("input").removeAttribute("checked");
      });

      if (el.checked) {
        li.add("selected");
        el.setAttribute("checked", "checked");
      }
    }
  }

  if (el && el.parentNode.parentNode.classList.contains("filter-options")) {
    // target filter checkboxes

    const parentWrapper = el.parentNode.parentNode;
    const isAllCheck = item => item.classList.contains("js-all-checkbox");
    const hasCatId = el => el.parentNode.dataset.categoryId;
    const allCheck = parentWrapper.querySelector(".js-all-checkbox");

    if (el.checked) {
      // if checking the all checkbox
      if (isAllCheck(el)) {
        // uncheck all checkbox siblings
        parentWrapper.querySelectorAll('[type="checkbox"]').forEach(check => {
          if (!isAllCheck(check)) check.checked = false;
        });
        if (parentWrapper.parentNode.dataset.categoryList !== undefined) {
          // if not checking inside sub sections
          document
            .querySelectorAll("[data-parent-category-id]")
            .forEach(item => (item.style.display = "none")); // hide all sub sections
        }
      } else {
        // otherwise uncheck the all checkbox
        if (allCheck) allCheck.checked = false;
      }
    } else {
      // if not checking the all checkbox
      if (!isAllCheck(el)) {
        const someChecked = Array.prototype.slice
          .call(parentWrapper.querySelectorAll('[type="checkbox"]'))
          .some(x => x.checked);
        if (!someChecked) {
          if (allCheck) allCheck.checked = true;
        }
      }
    }

    // show hide sub products
    if (hasCatId(el)) {
      // if <li> has data attr
      const productParentWrapper = el.parentNode.parentNode;
      let i = 0;
      const getCheck = () => {
        // function checks if only one checkbox is checked
        productParentWrapper
          .querySelectorAll('[type="checkbox"]')
          .forEach(check => {
            if (check.checked) i++;
          });
        if (i == 1) {
          i = 0;
          return Number(
            hasCatId(
              productParentWrapper.querySelector('[type="checkbox"]:checked')
            )
          );
        }
        return 0;
      };

      document
        .querySelectorAll("[data-parent-category-id]")
        .forEach(item => (item.style.display = "none")); // hide all sub sections

      if (getCheck() !== 0) {
        // if there is one checkbox selected
        const sections = document.querySelectorAll(
          `[data-parent-category-id="${getCheck()}"]`
        );

        if (sections) {
          sections.forEach(section => (section.style.display = "block"));
        }
      } else {
        // if nothing is checked
        document
          .querySelectorAll("[data-parent-category-id]")
          .forEach(section => {
            // go back to default state (all checkbox checked)
            section
              .querySelectorAll('[type="checkbox"]')
              .forEach(check => (check.checked = isAllCheck(check)));
          });
      }

      // third tier checkboxes
      let j = 0;
      let thirdArr = [];
      const thirdTierSections = document.querySelectorAll(
        "[data-third-tier-id]"
      );

      productParentWrapper
        .querySelectorAll('[type="checkbox"]')
        .forEach(check => {
          if (check.checked) {
            thirdArr.push(hasCatId(check));
          }
        });

      if (thirdArr.indexOf("1115") !== -1) {
        if (thirdArr.indexOf("1294") !== -1) {
          thirdTierSections.forEach(section => {
            section.style.display = "block";
          });
        } else {
          thirdTierSections.forEach(section => {
            if (section.dataset.thirdTierId === "1115") {
              section.style.display = "block";
            }
          });
        }
      } else {
        thirdTierSections.forEach(section => (section.style.display = "none"));
      }
    }
  }
});

// shamelessly stolen / modified from https://www.w3schools.com/howto/tryit.asp?filename=tryhow_js_autocomplete
export default function autocomplete(el, arr) {
  /*the autocomplete function takes two arguments,
    the text field element and an array of possible autocompleted values:*/
  var currentFocus;
  /*execute a function when someone writes in the text field:*/
  let list = [];
  let arrClone = arr.slice(0);
  document.body.addEventListener("input", function(e) {
    if (e.target.id == el) {
      var a,
        b,
        i,
        val = e.target.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("div");
      a.setAttribute("id", "autocomplete-items");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      e.target.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arrClone.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        if (
          arrClone[i].SwatchName.substr(0, val.length).toUpperCase() ==
          val.toUpperCase()
        ) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("div");
          /*make the matching letters bold:*/
          b.innerHTML = `<img src="${arrClone[i].SwatchUrl}">`;
          b.innerHTML += arrClone[i].SwatchName.substr(0, val.length);
          b.innerHTML += arrClone[i].SwatchName.substr(val.length);
          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML +=
            "<input type='hidden' value='" + arrClone[i].SwatchName + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function(e) {
            /*insert the value for the autocomplete text field:*/
            list.push(this.getElementsByTagName("input")[0].value);
            // update search list
            this.parentNode.parentNode.querySelector(
              "#SelectedSpecies"
            ).value = list;
            // update autocomplete list
            updateList(
              this.parentNode.parentNode.parentNode.querySelector(
                "#autocomplete-selected"
              ),
              list
            );
            closeAllLists();
          });
          a.appendChild(b);
        }
      }

      if (a.innerHTML === "") {
        a.innerHTML =
          '<div class="no-results">No colours or species found.</div>';
      }
    }
  });
  /*execute a function presses a key on the keyboard:*/
  document.body.addEventListener("keydown", function(e) {
    if (e.target.id == el) {
      var x = document.getElementById("autocomplete-items");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) {
        //up
        /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    }
  });
  function addActive(x) {
    /*a function to classify an item as "active":*/
    if (!x) return false;
    /*start by removing the "active" class on all items:*/
    removeActive(x);
    if (currentFocus >= x.length) currentFocus = 0;
    if (currentFocus < 0) currentFocus = x.length - 1;
    /*add class "autocomplete-active":*/
    x[currentFocus].classList.add("autocomplete-active");
  }
  function removeActive(x) {
    /*a function to remove the "active" class from all autocomplete items:*/
    for (var i = 0; i < x.length; i++) {
      x[i].classList.remove("autocomplete-active");
    }
  }
  function closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
        except the one passed as an argument:*/
    var x = document.getElementsByClassName("autocomplete-items");
    for (var i = 0; i < x.length; i++) {
      if (elmnt != x[i] && elmnt != document.getElementById(el)) {
        x[i].parentNode.removeChild(x[i]);
      }
    }
  }
  function updateList(listEl, arr) {
    listEl.innerHTML = "";
    arr.map(item => {
      listEl.innerHTML += `<div class="autocomplete-item js-autocomplete-item" data-autocomplete-name="${item}">${item}</div>`;
    });
  }
  document.addEventListener("click", function(e) {
    /*execute a function when someone clicks in the document:*/
    closeAllLists(e.target);

    // remove a list item
    if (e.target.classList.contains("js-autocomplete-item")) {
      e.preventDefault();
      list = list.filter(item => item !== e.target.dataset.autocompleteName);
      e.target.parentNode.parentNode.querySelector(
        "#SelectedSpecies"
      ).value = list;
      updateList(
        e.target.parentNode.parentNode.querySelector("#autocomplete-selected"),
        list
      );
    }

    // empty list
    if (e.target.id == "reset-filters") {
      document.querySelector("#SelectedSpecies").value = "";
      document.querySelector("#autocomplete-selected").innerHTML = "";
      list = [];
      arrClone = arr.slice(0);
    }
  });
}

// search results
const allResults = document.querySelector(".all-results");

if (allResults) {
  const showMore = document.querySelector(".js-show-more");
  const elements = allResults.querySelectorAll(".search-result");
  let i = 3;
  const loadAmount = 3;
  if (showMore) {
    showMore.addEventListener("click", function() {
      const amount = i + loadAmount;
      for (let index = i; index < amount; index++) {
        elements[index].classList.remove("hide-result");
        if (index + 1 >= elements.length) {
          this.style.display = "none";
        }
      }
      i += loadAmount;
    });
  }
}
