import "whatwg-fetch";

export default function checkout() {
  var checkEmail = document.querySelector('.check-email')

  const pageHeading = document.querySelector('.page-heading-wrapper h1')
  const bread = document.querySelector('.breadcrumb li:last-child a')

  if(pageHeading && pageHeading.innerHTML === 'Email verification') {
    if(bread) {
      bread.innerHTML = pageHeading.innerHTML
    }
  }

  function sendCode(e) {
    e.preventDefault();
    var email = document.querySelector('#enter-email-address');
    var url = '/Umbraco/Api/Organization/GetSendUserEmail?Email=' + email.value;
    var confirmationCodeError = document.querySelector('#error-for-confirmation-code');
    document.getElementById("email").innerHTML = email.value;

    document.querySelector('.hide-on-waiting').classList.add('is-hidden');
    document.querySelector('.awaiting-code').classList.remove('is-hidden');
    document.querySelector('.button-group .check-email').innerHTML = "Submit code";
    document.querySelector(".page-heading-wrapper h1").innerHTML = "Enter verification code";
    document.querySelector('.breadcrumb li:last-child a').innerHTML = 'Enter verification code';

    if (confirmationCodeError) {
      confirmationCodeError.style.display = 'none'
    }

    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      return response.json()
    }).then(function (result) {
      awaitCode()
    })
  }
  
  function doStuffWithEmail() {
    var success = document.querySelector('.success');
    var failure = document.querySelector('.failure');
    var checkEmail = document.querySelector('.check-email');
    var email = document.querySelector('#enter-email-address');
    var nodetails = document.querySelector(".nodetails");
    var yesdetails = document.querySelector(".yesdetails");

    // remove existing listener from check email button
    checkEmail.removeEventListener('click', checkEmailClick);
    success.classList.remove('is-hidden');
    failure.classList.add('is-hidden');
    checkEmail.innerHTML = 'Send code';
    email.classList.add('email-success');
    email.classList.remove('email-failure');

    // send code to email address
    checkEmail.addEventListener('click', sendCode);

    var len = email.value.length;
    email.addEventListener('keyup', function () {
      if (email.value.length, len) {
        nodetails.classList.add('is-hidden');
        yesdetails.classList.add('is-hidden');
        email.classList.remove('email-success');
        checkEmail.removeEventListener('click', sendCode);
        checkEmail.addEventListener('click', checkEmailClick);
        checkEmail.innerHTML = 'Continue'
      }
    });
  }

  function awaitCode() {
    var checkEmail = document.querySelector('.check-email');
    checkEmail.removeEventListener('click', sendCode);
    checkEmail.addEventListener('click', submitCode);
  }

  function submitCode(e) {
    e.preventDefault();
    var email = document.querySelector('#enter-email-address').value;
    var confirmationCode = document.querySelector('#confirmation-code');
    var confirmationCodeError = document.querySelector('#error-for-confirmation-code');

    if (confirmationCode.value) {
      var code = confirmationCode.value;
      var url = '/Umbraco/Api/Organization/GetAddressDetails?Email=' + email + '&Code=' + code;
      var awaitingCode = document.querySelector('.awaiting-code');

      fetch(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result) {
          if(result.PermanentCode) {
            document.cookie = `permanent_code=${result.PermanentCode}; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/`
            document.cookie = `permanent_email=${email}; expires=Tue, 1 Jan 2030 12:00:00 UTC; path=/`
          }

          // populate address details
          awaitingCode.remove();

          document.querySelector('.email-hidden').classList.remove('is-hidden')
          document.querySelector('#email-address').value = document.querySelector('#enter-email-address').value
          document.querySelector('.hide-on-waiting').classList.add('is-hidden')
          document.querySelector('.details-form').classList.remove('is-hidden')
          document.querySelector('.yesdetails').classList.add('is-hidden')
          document.querySelector("[for='one-off-address']").classList.add('is-hidden')
          document.querySelector("[for='Newsletter2']").classList.add('is-hidden')
          document.querySelector('.page-heading-wrapper h1').innerHTML = 'Order details'
          document.querySelector('.breadcrumb li:last-child a').innerHTML = 'Order details'
          document.querySelector('.check-email').innerHTML = 'Confirm sample order'

          for (var key in result) {
            if (result.hasOwnProperty(key)) {
              var input = document.querySelector('[name=' + key + ']');
              if (input) {
                input.value = result[key];
              }
            }
          }
          
          awaitSubmit()
        
        } else if (confirmationCode.value.length !== 0) {
          confirmationCode.classList.add('error');
          confirmationCodeError.style.display = 'block';
          confirmationCodeError.style.visibility = 'visible';
          confirmationCodeError.innerHTML = 'Invalid code. Please try again.';
        }
      })
    } else {
      confirmationCode.classList.add('error');
      confirmationCodeError.style.display = 'block';
      confirmationCodeError.style.visibility = 'visible';
      confirmationCodeError.innerHTML = 'This field is mandatory.';
    }
  }
  function awaitSubmit() {
    document.querySelector('.check-email').removeEventListener('click', submitCode);
    document.querySelector('.check-email').addEventListener('click', function (e) {
      e.preventDefault();

      // Add errors
      if (document.querySelector("[name='DeliveryAddress1']").value == "") {
        document.querySelector("[name='DeliveryAddress1']").classList.add("error");
      }
      if (document.querySelector("[name='DeliveryAddress2']").value == "") {
        document.querySelector("[name='DeliveryAddress2']").classList.add("error");
      }
      if (document.querySelector("[name='DeliveryTownCity']").value == "") {
        document.querySelector("[name='DeliveryTownCity']").classList.add("error");
      }
      if (document.querySelector("[name='DeliveryPostCode']").value == "") {
        document.querySelector("[name='DeliveryPostCode']").classList.add("error");
      }
      if (document.querySelector("[name='DeliveryContactNumber']").value == "") {
        document.querySelector("[name='DeliveryContactNumber']").classList.add("error");
      }
      if (document.querySelector("[name='DeliveryTownCity']").value == "") {
        document.querySelector("[name='DeliveryTownCity']").classList.add("error");
        }
        var response = grecaptcha.getResponse();
        //if (response.length == 0) {
        //    //reCaptcha not verified
        //    document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">reCaptcha error occurred. Please try again.</span>';
        //}
        

        if (document.querySelector("[name='DeliveryAddress1']").value == "" || document.querySelector("[name='DeliveryAddress2']").value == "" || document.querySelector("[name='DeliveryTownCity']").value == "" || document.querySelector("[name='DeliveryPostCode']").value == "" || document.querySelector("[name='DeliveryContactNumber']").value == "" || document.querySelector("[name='DeliveryTownCity']").value == "") { } else if (response.length == 0) { document.getElementById('g-recaptcha-error').innerHTML = '<span style="color:red;">reCaptcha error occurred. Please try again.</span>'; } else {
        document.getElementById('checkout-form').submit();
      }
    });
  }

  function checkEmailClick(e) {
    var email = document.querySelector('#enter-email-address');
    

    const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)permanent_code\s*\=\s*([^;]*).*$)|^.*$/, "$1")
    const cookieEmailValue = document.cookie.replace(/(?:(?:^|.*;\s*)permanent_email\s*\=\s*([^;]*).*$)|^.*$/, "$1")

    if(cookieValue && email.value === cookieEmailValue) {
      var confirmationCode = document.querySelector('#confirmation-code');
      var url = '/Umbraco/Api/Organization/GetAddressDetails?Email=' + email.value + '&Code=' + cookieValue;
      var awaitingCode = document.querySelector('.awaiting-code');
 
      fetch(url, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(function (response) {
        return response.json();
      }).then(function (result) {
        if (result) {
          // populate address details
          awaitingCode.remove();
          document.querySelector('.details-form').classList.remove('is-hidden')
          document.querySelector('.email-hidden').classList.remove('is-hidden')
          document.querySelector('#email-address').value = document.querySelector('#enter-email-address').value
          document.querySelector('.hide-on-waiting').classList.add('is-hidden')
          document.querySelector("[for='one-off-address']").classList.add('is-hidden')
          document.querySelector("[for='Newsletter2']").classList.add('is-hidden')
          document.querySelector('.page-heading-wrapper h1').innerHTML = 'Order details'
          document.querySelector('.breadcrumb li:last-child a').innerHTML = 'Order details'
          document.querySelector('.check-email').innerHTML = 'Confirm sample order'

          for (var key in result) {
            if (result.hasOwnProperty(key)) {
              var input = document.querySelector('[name=' + key + ']')
              if (input) {
                input.value = result[key]
              }
            }
          }

          awaitSubmit()
          
        } else {
          yesdetails.classList.remove('is-hidden')
          nodetails.classList.add('is-hidden')
        }
      })
    } else {
      let url = '/Umbraco/Api/Organization/GetUserAlreadyRegistered?Email=' + email.value;
      let confirmationCode = document.querySelector('#confirmation-code');
      let confirmationCodeError = document.querySelector('#error-for-confirmation-code');
      let yesdetails = document.querySelector(".yesdetails");
      let nodetails = document.querySelector(".nodetails");
      let awaitingCode = document.querySelector('.awaiting-code');

    fetch(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(function (response) {
      return response.json();
    }).then(function (result) {
      if (!result) {
        nodetails.classList.remove('is-hidden')
        yesdetails.classList.add('is-hidden')
        awaitingCode.remove()

        document.querySelector('.details-form').classList.remove('is-hidden')
        document.querySelector("[for='Newsletter2']").classList.add('is-hidden')
        document.querySelector(".button-group .button").innerHTML = "Confirm sample order";
        document.querySelector(".page-heading-wrapper h1").innerHTML = "Order details";

        awaitSubmit()
        
        return
      } else {
        yesdetails.classList.remove('is-hidden')
        nodetails.classList.add('is-hidden')
      }

      if (!email.classList.contains('error')) {
        if (confirmationCode) {
          confirmationCode.classList.remove('error');
        }

        if (confirmationCodeError) {
          confirmationCodeError.style.display = 'none';
        }

        doStuffWithEmail();
      }
      else {
          if(confirmationCode) {
            confirmationCode.classList.add('error');
          }

          if (confirmationCodeError) {
              confirmationCodeError.style.display = 'block';
          }
      }
    });
    }
  }

  (() => {
    if (checkEmail) {
      checkEmail.addEventListener('click', checkEmailClick);
    }
  })()
}
