// ie fixes
import "whatwg-fetch";
import "@babel/polyfill";

// forEach pollyfill
if (typeof NodeList.prototype.forEach !== "function") {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// Create Element.remove() function if not exist
if (!("remove" in Element.prototype)) {
    Element.prototype.remove = function() {
        if (this.parentNode) {
            this.parentNode.removeChild(this);
        }
    };
}      

/***** components *****/
import CollectionBar from "./ug/collectionBar";
import header from "./ug/header.js";
import carouselInit from "./ug/carousel.js";
import interactivePanel from "./ug/interactivePanel.js";
import { showModal, hideModal, autoShowModal } from "./ug/modal";
import productFilters from "./ug/productFilters";
import ResourceFilters from "./ug/resourceFilters";
import loadMorePage from "./ug/loadMorePage";  
import Collection from "./ug/collection";
import Swatches from "./ug/swatches";
import CollectionSlider from "./common/collectionSlider"; 
import Form from "./common/form";
import sampleOrderCart from "./common/sampleOrderCart";
import checkout from "./common/checkout";
import loadCollectionListeners from "./common/showcasePage";
import { addListeners } from "./common/collectionPage";

require("./ug/search");

document.addEventListener("DOMContentLoaded", () => {
    window.stagedProducts = new Set();

    // all pages
    header();

    // load components
    window.collectionBar = new CollectionBar();
    window.collectionBar.load();

    // collection page
    const scrollPosition = document.querySelectorAll(".scroll-position");

    if (scrollPosition) {
        scrollPosition.forEach(s => {
            s.addEventListener("click", () => {
                sessionStorage.setItem("scrollP", window.scrollY);
            });
        });
    }

    if (sessionStorage.getItem("scrollP")) {
        window.scrollTo({
            top: sessionStorage.getItem("scrollP")
        });
        sessionStorage.removeItem("scrollP");
    }

    addListeners();
    window.loadCollectionListeners = loadCollectionListeners;
    window.loadCollectionListeners();

    // home page interactive panel
    const interactivePanelEl = document.querySelector(".js-interactive-panel");
    if (interactivePanelEl) interactivePanel(interactivePanelEl);

    // carousel
    const carousels = document.querySelectorAll("[data-carousel]");
    if (carousels) carousels.forEach(carouselInit);

    // modal
    window.showModal = showModal;
    window.hideModal = hideModal;
    autoShowModal();

    window.addEventListener("popstate", function(e) {
        autoShowModal();
    });

    // product filters
    productFilters("filter-dropdown");

    // Load more, category switching and live search
    if (document.querySelector(".LoadMorePage")) loadMorePage();

    // resource filters
    if (document.querySelector(".js-resource-filters")) {
        let resourceFilters = new ResourceFilters();
        resourceFilters.load();
    }

    // collection
    window.collection = new Collection();
    window.collection.load();
    window.collection.updateCount();

    // collection sliders
    if (document.getElementsByClassName("collection-grid")) {
        let collectionGrids = document.getElementsByClassName(
            "collection-grid"
        );
        for (let grid of collectionGrids) {
            new CollectionSlider(grid);
        }
    }

    // swatches
    if (document.getElementsByClassName("select-product").length) {
        let swatches = new Swatches();
        swatches.load();
    }

    // sample cart
    if (document.querySelector(".SampleOrderCart")) {
        sampleOrderCart();
    }

    // checkout
    if (document.querySelector("#checkout-form")) {
        checkout();
    }

    // form functionality and validation
    const forms = document.querySelectorAll("[data-form-validation]");
    if (forms) {
        for (let form of forms) {
            new Form(form).load();
        }
    }

    // form functionality and validation
    const numberInputs = document.querySelectorAll(".form-number");
    if (numberInputs) {
        numberInputs.forEach(numberInput => {
            let input = numberInput.querySelector('input[type="number"]'),
                btnUp = numberInput.querySelector(".quantity-up"),
                btnDown = numberInput.querySelector(".quantity-down"),
                min = input.min,
                max = input.max,
                newVal;

            btnUp.addEventListener("click", function customEvent(event) {
                input = numberInput.querySelector('input[type="number"]');
                var oldValue = parseInt(input.value);
                if (oldValue >= max) {
                    newVal = oldValue;
                } else {
                    newVal = oldValue + 1;
                }
                input.value = `${newVal}`;

                fetch("/umbraco/surface/cart/savecartitem", {
                    method: "POST",
                    body: JSON.stringify({
                        sku: input.dataset.sku,
                        quantity: newVal
                    }),
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        document.querySelector(".js-cart-count").innerHTML =
                            res.TotalCartCount;
                    });
            });

            btnDown.addEventListener("click", function customEvent(event) {
                input = numberInput.querySelector('input[type="number"]');
                var oldValue = parseFloat(input.value);
                if (oldValue <= min) {
                    newVal = oldValue;
                } else {
                    newVal = oldValue - 1;
                }
                input.value = `${newVal}`;

                fetch("/umbraco/surface/cart/savecartitem", {
                    method: "POST",
                    body: JSON.stringify({
                        sku: input.dataset.sku,
                        quantity: newVal
                    }),
                    credentials: "same-origin",
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(res => {
                        return res.json();
                    })
                    .then(res => {
                        document.querySelector(".js-cart-count").innerHTML =
                            res.TotalCartCount;
                    });
            });
        });
    }
});
    